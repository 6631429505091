import React from 'react'

import dynamic from 'next/dynamic'
import ModalQuoteContextProvider from '@/contexts/modalQuoteContext'
import ModalDownloadContextProvider from '@/contexts/modalDownloadContext'

import { ModalDownloadFragment } from '@/queries/fragments/Modal/Download.generated'
import { ModalQuoteFragment } from '@/queries/fragments/Modal/Quote.generated'

import { ModalProps, QuoteModalProps, UsedOrCharterProductModalProps } from '@/components/UI/Modal/types'
import { BlokZone } from '@/components/UI/BlokZone'
import Components from '@/components/Components'
import { Content } from '@/queries/schema.generated'
import { HeaderZone } from '@/components/UI/HeaderZone'

const DownloadModal = dynamic(() => import('@/components/Modal/Download'), { ssr: false })
const FamilyQuoteModal = dynamic(() => import('@/components/Family/Modal/Quote'), { ssr: false })

interface Props {
  content?: Content
  downloadData?: ModalDownloadFragment
  quoteData?: ModalQuoteFragment
  selectedModal?: ModalProps
  handleCloseModal?: () => void
  setOpenModal?: (props: ModalProps) => void
}

const Presentational: React.FC<React.PropsWithChildren<Props>> = ({
  content,
  downloadData,
  quoteData,
  setOpenModal,
  selectedModal,
  handleCloseModal,
}) => {
  const body = content?.body
  const header = content?.header

  const selectModalQuoteProps = ['quote', 'usedProductQuote', 'charterProductQuote'].includes(selectedModal?.type)
    ? (selectedModal as QuoteModalProps | UsedOrCharterProductModalProps)
    : undefined

  return (
    <>
      <ModalQuoteContextProvider modalQuoteData={quoteData ?? undefined}>
        <ModalDownloadContextProvider modalDownloadData={downloadData}>
          <section>
            <HeaderZone header={header} componentLookup={Components} />
          </section>
          <BlokZone bloks={body} componentLookup={Components} additionalProps={{ setOpenModal }} />
        </ModalDownloadContextProvider>
      </ModalQuoteContextProvider>
      {downloadData && (
        <DownloadModal
          selectedModal={selectedModal?.type === 'download' ? selectedModal : null}
          handleCloseModal={handleCloseModal}
          modalDownload={downloadData.content}
        />
      )}
      {quoteData && (
        <FamilyQuoteModal
          selectedModal={selectModalQuoteProps}
          handleCloseModal={handleCloseModal}
          modalQuote={quoteData.content}
        />
      )}
    </>
  )
}

export default Presentational
